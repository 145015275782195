<template>
  <div id="stats-index">
    <Headline :text="$t('stats_account.heading')" />
    <StatsAccount />
  </div>
</template>

<script>
import Headline from '../../components/app/Headline'
import StatsAccount from '../../components/stats/StatsAccount'

export default {
  components: {
    Headline,
    StatsAccount
  }
}
</script>
