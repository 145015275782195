<template>
  <div>
    <v-tabs class="mb-4" next>
      <v-tab v-for="(tab, i) in this.tabs" :to="tab.url" :key="i">
        <span class="text">{{ tab.heading }}</span>
      </v-tab>
    </v-tabs>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tabs: [
        { url: '/stats/account/date', heading: this.$t('stats_account_date.heading') },
        { url: '/stats/account/week', heading: this.$t('stats_account_week.heading') },
        { url: '/stats/account/month', heading: this.$t('stats_account_month.heading') },
        { url: '/stats/account/year', heading: this.$t('stats_account_year.heading') }
      ]
    }
  }
}
</script>
